'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HomePageMain = /** @class */function () {
    function HomePageMain() {
        this.introDom = document.getElementById('intro');
        this.toggleTab();
    }
    HomePageMain.prototype.toggleTab = function () {
        var that = this;
        var tabFirstLis = _tool2.default.$_selectAll(this.introDom, 'ul li');
        var introBgDom = _tool2.default.$_select(this.introDom, '.intro-bg');
        tabFirstLis.forEach(function (item) {
            item.addEventListener('click', function () {
                tabFirstLis.forEach(function (tItem) {
                    return tItem.classList.remove('active');
                });
                item.classList.add('active');
                var className = item.getAttribute('data-type');
                var introTextItem = _tool2.default.$_select(that.introDom, ".intro-text-item.".concat(className));
                var introTextItems = _tool2.default.$_selectAll(that.introDom, '.intro-text-item');
                introTextItems.forEach(function (iItem) {
                    return iItem.classList.remove('active');
                });
                introTextItem.classList.add('active');
                switch (className) {
                    case 'video':
                    case 'online':
                        introBgDom.classList.remove('blue-bg');
                        introBgDom.classList.remove('green-bg');
                        introBgDom.classList.add('purple-bg');
                        break;
                    case 'office':
                    case 'system':
                    case 'picture':
                        introBgDom.classList.remove('purple-bg');
                        introBgDom.classList.remove('green-bg');
                        introBgDom.classList.add('blue-bg');
                        break;
                    case 'audio':
                    case 'data':
                        introBgDom.classList.remove('purple-bg');
                        introBgDom.classList.remove('blue-bg');
                        introBgDom.classList.add('green-bg');
                        break;
                }
            });
        });
        // 初始化
        var tabFirstLi = _tool2.default.$_select(this.introDom, 'ul li:first-child');
        tabFirstLi.classList.add('active');
        var introTextFirstItem = _tool2.default.$_select(this.introDom, '.intro-text-item:first-child');
        introTextFirstItem.classList.add('active');
        introBgDom.classList.add('purple-bg');
    };
    return HomePageMain;
}();
exports.default = HomePageMain;